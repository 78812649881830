.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0c0d0e;
    color: #ecf0f1;
    padding: 20px;
}

.logo-section .logo, .logo-vmkm {
    margin-left: 2px;
}

.logo-section .logo {
    width: 100px;
}

.nav-section {
    display: flex;
    gap: 20px;
}

.nav-link {
    color: #ecf0f1;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}

.nav-link:hover {
    color: #3498db;
}

.logo-vmkm {
    width: 50%;
    margin-left: 12px;
    /* height: 100px; */
}

.slogan {
    color: darkgrey;
    font-size: x-large;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none
}

/* Media Queries for Mobile Responsiveness */
@media only screen and (max-width: 768px) {
    .logo-section .logo {
        width: 80px;
    }
    .logo-vmkm {
        width: 150px;
        height: 80px;
    }
    .slogan {
        font-size: large;
    }
}

@media only screen and (max-width: 480px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .logo-section {
        margin-bottom: 10px;
    }

    .logo-section .logo {
        width: 60px;
    }

    .logo-vmkm {
        width: 100px;
        height: 50px;
    }

    .slogan {
        font-size: medium;
        text-align: center;
        width: 100%;
    }
}