
.hero {
    position: relative;
    width: 100%;
    height: 100%;
}

.slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.slide-image {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}

/* Media queries for responsiveness */
@media only screen and (max-width: 768px) {
    .slide {
        height: 60vh; /* Adjust height for mobile devices */
    }
    /* Additional mobile-specific styles can be added here */
}

@media only screen and (max-width: 480px) {
    .slide {
        height: 50vh; /* Adjust height for smaller devices */
    }
    /* Additional smaller device-specific styles can be added here */
}