.contact-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .contact-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
    color: #333333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-size: 1em;
    margin-bottom: 6px;
  }
  
  input,
  textarea,
  button {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  button {
    background-color: #3b6bae;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #336bba;
  }
  