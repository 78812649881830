.locations-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    
}

.location-row {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: center;
}

.card {
    display: flex;
    flex-direction: column;
    margin: 25px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    background-color: #fff;
    height: 80vh;
    width: 60vw;
}

.location-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card p span{
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(34, 33, 33);
}

.locations-h1 {
    margin-bottom: 20px;
    font-size: 36px;
    color: rgb(34, 33, 33);
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}


.view-more-button {
    /* display: inline-block; */
    margin: 20px 0;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn {
    margin-left: 50%;
}

/* Mobile responsiveness */
@media only screen and (max-width: 768px) {
    .locations-container {
        padding: 20px;
    }
    .card {
        margin: 15px;
    }
    .locations-h1 {
        font-size: 28px;
    }
    .location-image {
        height: 150px;
    }
    .card p span{
        font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {
    .locations-container {
        padding: 15px;
        padding-top: 95%;
    }
    .card {
        margin: 10px;
        width: 80%; 
        height: 60vh;
        /* width: 60vw; */
    }
    .locations-h1 {
        font-size: 24px;
    }
    .location-image {
        /* height: 120px; */
         /* width: 100%; */
        height: 50%; 
        /* object-fit: cover; */
    }
    .card p span{
        font-size: 8px;
    }
    .location-row {
       
        flex-wrap: wrap;
    }
}
