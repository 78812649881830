.footer {
    justify-content: space-around;
    align-items: center;
    background-color: #0c0d0e;
    color: #ecf0f1;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
}

.contact {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.footer-section {
    text-align: center;

}

.footer-section h4 {
    margin-bottom: 10px;
    font-size: 18px;
    color: rgb(214, 213, 213);
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}

.footer-section p {
    margin-bottom: 10px;
    font-size: 16px;
    color: darkgrey;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
}

.anchor {
    color: #ecf0f1;
    text-decoration: none;
}

.copyright {
    justify-content: space-around;
    align-items: center;
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }

    .footer-section {
        margin-bottom: 20px;
    }

    .footer-section h4 {
        font-size: 16px;
    }

    .footer-section p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 15px;
    }

    .footer-section h4 {
        font-size: 14px;
    }

    .footer-section p {
        font-size: 12px;
    }
}
