.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 20px;
}

.image-section, .text-section {
    flex: 1;
    margin: 20px;
}

.about-image {
    width: 100%;
    height: 80vh;
    /* object-fit: cover; */
    border-radius: 50%;
}

.text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text-section h1 {
    margin-bottom: 20px;
    font-size: 36px;
    color: rgb(34, 33, 33);
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}

.text-section p span{
    font-size: 14px;
    line-height: 1.6;
    color: rgb(56, 54, 54);
    /* font-size: x-large; */
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
}

/* Media Queries for Mobile Responsiveness */
@media only screen and (max-width: 768px) {
    .image-section, .text-section {
        /* padding: 20px; */
    }
    .about-image {
        height: 40vh;
    }
    .text-section h1 {
        font-size: 28px;
    }
    .text-section p span{
        font-size: small;
    }
}

@media only screen and (max-width: 480px) {
    .about-container {
        flex-direction: column;
        /* margin-top: 25%; */
        margin-top: 95%;
        margin-bottom: 10px;;
    }
    .image-section {

        /* margin-top: 10px; */
    }
    .image-section, .text-section {
        padding: 15px;
    }
    .about-image {
        height: 30vh;
        border-radius: 50%;
    }
    .text-section h1 {
        font-size: 24px;
    }
    .text-section p span {
        font-size: medium;
    }
}
